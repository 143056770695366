<template>
    <b-dropdown class="mb-1"
                left
                variant="primary"
                size="sm"
    >
        <template #button-content>
            {{ $t('common.label.actions') }}
        </template>

        <b-dropdown-item v-if="row.item.total"
                         @click="$root.$children[0].openModal('customer-modal', {customerId:row.item.customer_id,tab:'offerings'})">
            {{ $t('common.button.operations') }}&nbsp;({{ row.item.total }})
        </b-dropdown-item>
        <b-dropdown-item
            @click="sendMail(row.item.id)">
            <font-awesome-icon class="mr-2" icon="envelope"/>
            {{ $t('common.button.send_mail') }}
        </b-dropdown-item>
        <b-dropdown-item
            @click="duplicate(row.item.id)"
            v-if="row.item.customer_active"
        >
            {{ $t('common.button.duplicate') }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        sendMail(id) {
            this.$root.$children[0].openModal('email-modal', {
                documentId: id,
                docType: 'offering_email',
            }, this.refreshTable, {})
        },
        duplicate(id) {
            this.$root.$children[0].openModal('duplicate-offering-modal', {
                id: id,
            }, this.refreshTable, {width: '800px'})
        }
    }
}
</script>